import baseGithub from "../../../../../docs/blog/2020-01-08-git-workflows/base-github.png";
import initialSvg from "../../../../../docs/blog/2020-01-08-git-workflows/1-initial.svg";
import additionalWorkSvg from "../../../../../docs/blog/2020-01-08-git-workflows/2-additional-work.svg";
import rebasedSvg from "../../../../../docs/blog/2020-01-08-git-workflows/3-rebased.svg";
import mergeSrc from "../../../../../docs/blog/2020-01-08-git-workflows/merge.png";
import mergeCommitSvg from "../../../../../docs/blog/2020-01-08-git-workflows/4-merge-commit.svg";
import actualGitSvg from "../../../../../docs/blog/2020-01-08-git-workflows/6-actual-git.svg";
import parallelUniversesSvg from "../../../../../docs/blog/2020-01-08-git-workflows/7-parallel-universes.svg";
import doubleWorkSvg from "../../../../../docs/blog/2020-01-08-git-workflows/8-double-work.svg";
import moreIncrementalSvg from "../../../../../docs/blog/2020-01-08-git-workflows/5-more-incremental.svg";
import React from 'react';
export default {
  baseGithub,
  initialSvg,
  additionalWorkSvg,
  rebasedSvg,
  mergeSrc,
  mergeCommitSvg,
  actualGitSvg,
  parallelUniversesSvg,
  doubleWorkSvg,
  moreIncrementalSvg,
  React
};