// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-template-feature-comparison-js": () => import("./../src/templates/template-feature-comparison.js" /* webpackChunkName: "component---src-templates-template-feature-comparison-js" */),
  "component---src-templates-template-blog-list-js": () => import("./../src/templates/template-blog-list.js" /* webpackChunkName: "component---src-templates-template-blog-list-js" */),
  "component---src-templates-template-blog-post-js": () => import("./../src/templates/template-blog-post.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-template-contributor-page-js": () => import("./../src/templates/template-contributor-page.js" /* webpackChunkName: "component---src-templates-template-contributor-page-js" */),
  "component---src-templates-template-docs-markdown-js": () => import("./../src/templates/template-docs-markdown.js" /* webpackChunkName: "component---src-templates-template-docs-markdown-js" */),
  "component---src-templates-template-api-markdown-js": () => import("./../src/templates/template-api-markdown.js" /* webpackChunkName: "component---src-templates-template-api-markdown-js" */),
  "component---src-templates-template-docs-local-packages-js": () => import("./../src/templates/template-docs-local-packages.js" /* webpackChunkName: "component---src-templates-template-docs-local-packages-js" */),
  "component---src-templates-template-showcase-details-js": () => import("./../src/templates/template-showcase-details.js" /* webpackChunkName: "component---src-templates-template-showcase-details-js" */),
  "component---src-templates-template-starter-page-js": () => import("./../src/templates/template-starter-page.js" /* webpackChunkName: "component---src-templates-template-starter-page-js" */),
  "component---src-templates-template-creator-details-js": () => import("./../src/templates/template-creator-details.js" /* webpackChunkName: "component---src-templates-template-creator-details-js" */),
  "component---src-templates-template-docs-remote-packages-js": () => import("./../src/templates/template-docs-remote-packages.js" /* webpackChunkName: "component---src-templates-template-docs-remote-packages-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tags-js": () => import("./../src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-creators-agencies-js": () => import("./../src/pages/creators/agencies.js" /* webpackChunkName: "component---src-pages-creators-agencies-js" */),
  "component---src-pages-creators-companies-js": () => import("./../src/pages/creators/companies.js" /* webpackChunkName: "component---src-pages-creators-companies-js" */),
  "component---src-pages-creators-index-js": () => import("./../src/pages/creators/index.js" /* webpackChunkName: "component---src-pages-creators-index-js" */),
  "component---src-pages-creators-people-js": () => import("./../src/pages/creators/people.js" /* webpackChunkName: "component---src-pages-creators-people-js" */),
  "component---src-pages-ecosystem-js": () => import("./../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-cms-js": () => import("./../src/pages/features/cms.js" /* webpackChunkName: "component---src-pages-features-cms-js" */),
  "component---src-pages-features-jamstack-js": () => import("./../src/pages/features/jamstack.js" /* webpackChunkName: "component---src-pages-features-jamstack-js" */),
  "component---src-pages-guidelines-color-js": () => import("./../src/pages/guidelines/color.js" /* webpackChunkName: "component---src-pages-guidelines-color-js" */),
  "component---src-pages-guidelines-design-tokens-js": () => import("./../src/pages/guidelines/design-tokens.js" /* webpackChunkName: "component---src-pages-guidelines-design-tokens-js" */),
  "component---src-pages-guidelines-logo-js": () => import("./../src/pages/guidelines/logo.js" /* webpackChunkName: "component---src-pages-guidelines-logo-js" */),
  "component---src-pages-guidelines-typography-js": () => import("./../src/pages/guidelines/typography.js" /* webpackChunkName: "component---src-pages-guidelines-typography-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-plugins-js": () => import("./../src/pages/plugins.js" /* webpackChunkName: "component---src-pages-plugins-js" */),
  "component---src-pages-showcase-js": () => import("./../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-starters-js": () => import("./../src/pages/starters.js" /* webpackChunkName: "component---src-pages-starters-js" */)
}

